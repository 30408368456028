import {Directive} from '@angular/core';

@Directive({
  selector: '[admShellHeaderToolbarRight]',
  standalone:false
})
export class ShellHeaderToolbarRightDirective {

  constructor() {
  }

}
