import {EnvConfig} from "./env-config";

export const environment: EnvConfig = {
  production: true,
  domainAppUri: 'https://api.nitrodev.ebitda.tech/domain-ws',
  runtimeConfigPath: '/config/nitro-config.json',
  configValues: {
    'codabox.importer.api.uri': 'https://codaimporter.nitro.valuya.be',
    'front.auth.extra.secure.routes': 'https://codaimporter.nitro.valuya.be',
    'front.error.notify.backend.enabled': true,
    'front.auth.issuer': 'https://sso.nitro.valuya.be/realms/nitro',
    "front.auth.clientId": 'nitro-admin-front',
    "indexer.ui.url": 'https://indexer.nitroprod.ebitda.tech',
    "front.env": "prod",
    'keycloak.url': 'https://sso.nitroprod.ebitda.tech/realms/nitro/account/',

  }
};
