import {Component, OnInit} from '@angular/core';
import {WsNitroRuleActionType} from "@fiscalteam/nitro-domain-client";
import {AccountingStatisticsDataComponent, NitroMessageService} from "@fiscalteam/ngx-nitro-services";
import {AdminFrontConfigService} from "../../config/admin-front-config.service";
import {CodaBatchService, ImportJob} from "../coda-batch-service";
import {ShellRoutesUtils} from "../shell-routes.utils";
import {BehaviorSubject} from "rxjs";
import {AdminFrontSharedModule} from "../../shared/admin-front-shared.module";
import {ShellPageTitleDirective} from "../../shared/shell-page-title.directive";

@Component({
  selector: 'adm-accounting-data-dashboard-route',
  templateUrl: './accounting-data-dashboard-route.component.html',
  standalone: true,
  imports: [
    AdminFrontSharedModule,
    AccountingStatisticsDataComponent,
    ShellPageTitleDirective
  ],
  styleUrls: ['./accounting-data-dashboard-route.component.scss']
})
export class AccountingDataDashboardRouteComponent implements OnInit {

  showStatusInfo = false;
  showConfigured = false;
  showCompleted = false;
  availableRuleActions: WsNitroRuleActionType[] = [];
  routeUtils = ShellRoutesUtils;
  showTrustee = true;

  allImportJobs: ImportJob[] = ['BANK_ACCOUNT_STATEMENT', 'SALES_INVOICE', 'PURCHASE_INVOICE', 'CREDIT_CARD_STATEMENT', 'PAYROLL_STATEMENT'];
  importJob$ = new BehaviorSubject<ImportJob | undefined>(undefined);

  constructor(
    private batchService: CodaBatchService,
    private messageService: NitroMessageService,
    private configService: AdminFrontConfigService,
  ) {
  }

  ngOnInit(): void {
    this.showConfigured = this.configService.isFeatureEnabled("data.configuration.visible")
    this.showCompleted = this.configService.isFeatureEnabled("data.export.visible")
    this.availableRuleActions = this.configService.getAvailableNitroRuleActionTypes();
  }

  onStartBatchClick(importJob: ImportJob) {
    this.batchService.triggerNewBatchInstance$(importJob, {}).subscribe({
      next: e => {
        this.messageService.showInfo(`Nouveau job lancé: ${e}`);
      },
      error: e => {
        this.messageService.showError(`Impossible de lancer ce batch:`, e);
      }
    });
  }
}
