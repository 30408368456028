import {Component, effect, model, OnInit} from '@angular/core';
import {WsNitroUser} from "@fiscalteam/nitro-domain-client";
import {ButtonLinkComponent, ConfigService, NitroLoggedUserService} from "@fiscalteam/ngx-nitro-services";
import {EMPTY, Observable} from "rxjs";
import {MenuItem} from "primeng/api";
import {ShellMenuService} from "../../shell-menu.service";
import {VERSION} from "../../../../version";
import {Router} from "@angular/router";
import {AsyncPipe, NgForOf, NgIf} from "@angular/common";
import {Button, ButtonDirective} from "primeng/button";
import {AdminFrontConfigService} from "../../../config/admin-front-config.service";

@Component({
  selector: 'adm-app-shell-menu',
  templateUrl: './app-shell-menu.component.html',
  standalone: true,
  imports: [
    NgIf,
    NgForOf,
    AsyncPipe,
    ButtonLinkComponent,
    ButtonDirective,
    Button
  ],
  styleUrls: ['./app-shell-menu.component.scss']
})
export class AppShellMenuComponent implements OnInit {

  loggedUser?: WsNitroUser;
  menu$: Observable<MenuItem[]> = EMPTY;
  version = VERSION;
  isDevelopment = false;
  frontEnv: string | undefined;
  keycloakUrl?: string;

  selectedTheme = model<'light' | 'dark'>('dark');

  constructor(private nitroLoggedUserService: NitroLoggedUserService,
              private menuService: ShellMenuService,
              private router: Router,
              private configService: ConfigService,
              private adminFrontConfigService: AdminFrontConfigService,
  ) {
    const envFromConfig = this.configService.getConfigValue("front.env");
    this.frontEnv = envFromConfig?.startsWith('prod') ? 'prod' : 'dev';
    this.isDevelopment = !this.frontEnv?.startsWith('prod');

    effect(() => {
      const theme = this.selectedTheme();
      this.setPageTheme(theme);
    });
  }

  async ngOnInit(): Promise<void> {
    this.menu$ = this.menuService.menu$;
    this.loggedUser = await this.nitroLoggedUserService.getLoggedUser$();
    this.keycloakUrl = this.adminFrontConfigService.getkeycloakUrl();
  }


  async onLogoutClick() {
    await this.nitroLoggedUserService.logout();
  }


  onTitleClick() {
    this.router.navigate(['/'])
  }

  onMenuItemClick(subItem: MenuItem) {
    this.menuService.updateMenuItem$(subItem, false);
  }

  switchDarkTheme() {
    const curTheme = this.selectedTheme();
    if (curTheme === "light") {
      this.selectedTheme.set('dark');
    } else {
      this.selectedTheme.set('light');
    }
  }

  private setPageTheme(theme: "light" | "dark" | undefined) {
    const doc = document;
    if (doc == null) {
      return;
    }
    const htmlElements = doc.getElementsByTagName('html');
    if (htmlElements && htmlElements.length === 1) {
      const htmlElement = htmlElements[0];
      if (theme == "light") {
        htmlElement.classList.remove('speed-dark')
      } else {
        htmlElement.classList.add('speed-dark')
      }
    }
  }
}
