import {computed, Injectable, signal} from "@angular/core";
import {AdminFrontAppContext} from "./admin-front-app-context";
import {toObservable} from "@angular/core/rxjs-interop";


@Injectable()
export class AdminFrontContextService {

  context = signal<AdminFrontAppContext>({});

  // Deprecated: use context directly
  contextTrustee$ = toObservable(computed(() => this.context().trustee));
  contextCustomer$ = toObservable(computed(() => this.context().customer));
  contextCustomerDocument$ = toObservable(computed(() => this.context().customerDocument));
  contextCustomerTransaction$ = toObservable(computed(() => this.context().customerTransaction));
  contextFinancialAccount$ = toObservable(computed(() => this.context().financialAccount));
  contextThirdparty$ = toObservable(computed(() => this.context().thirdParty));
  contextThirdpartyEntity$ = toObservable(computed(() => this.context().thirdPartyEntity));
  contextRule$ = toObservable(computed(() => this.context().nitroRule));
  contextRuleAction$ = toObservable(computed(() => this.context().nitroRuleAction));
  contextFinancialAccountProvider$ = toObservable(computed(() => this.context().financialAccountProvider));


  patchContext(key: keyof AdminFrontAppContext, value: { id: number } | null) {
    const curContext = this.context();
    const curValue = curContext ? curContext[key] : null;
    if (curValue === value) {
      return;
    }
    this.context.set({...curContext, ...{[key]: value}});
  }

  /**
   * Create a router link matching the trustee and customer context at the time of calling this method.
   */
  createTrusteeCustomerContextRouterLinkPrefix(): any[] {
    const context = this.context();
    const link: any[] = ['/'];
    const wsTrustee = context.trustee;
    if (wsTrustee) {
      link.push('trustee', wsTrustee.id);
    }
    const wsCustomer = context.customer;
    if (wsCustomer) {
      link.push('customer', wsCustomer.id);
    }
    return link;
  }


}
