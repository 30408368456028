<div class="app-shell flex h-screen relative">
  <div class="shell-messages block w-full absolute top-0 m-auto z-4 flex align-items-center flex-column">
    <div class="message-container  w-7 max-w-full">
      <p-message closable *ngFor="let msg of (messages$ | async) || []"
                 [severity]="msg.severity"
                 [text]="msg.summary"
      >
        {{ msg.detail }}
      </p-message>
    </div>
  </div>

  <div class="menu flex-0 relative">
    <button
      (click)="menuCollapsed = !menuCollapsed"
      *ngIf="menuCollapsed; else menu"
      [style]="{ marginTop: '1rem' }"
      class="p-button-outlined p-button-plain p-button-rounded p-button-sm absolute surface-overlay"
      type="button"
      pButton
    >
      <i class="pi pi-chevron-right"></i>
    </button>
    <ng-template #menu>
      <adm-app-shell-menu></adm-app-shell-menu>

      <button
        (click)="menuCollapsed = !menuCollapsed"
        [style]="{ top: '1rem', right: '-.8rem' }"
        class="p-button-outlined p-button-plain p-button-rounded p-button-sm absolute surface-overlay"
        type="button"
        pButton
      >
        <i class="pi pi-chevron-left"></i>
      </button>
    </ng-template>
  </div>

  <div class="content flex-1 ml-4 h-full relative overflow-hidden">
    <router-outlet></router-outlet>
  </div>
</div>

<p-toast
  [key]="ModalKeys.OPTIMISTIC_LOCK_MODAL_KEY"
  [preventOpenDuplicates]="true"
  position="center"
  styleClass="confirm-toast"
  (onClose)="onOptimsticLockModalClose()"
>
  <ng-template let-message pTemplate="message">
    <div class="optimistic-lock">
      <div class="header">
        <i class="pi pi-exclamation-triangle"></i>
        <span class="m-2 text-lg font-bold">{{ message.summary }}</span>
      </div>
      <p>
        La version du formulaire (ou d'une partie du formulaire) que vous voulez enregistrer semble avoir été
        modifiée depuis qu'il a été chargé. Cela peut arriver par exemple lorsque plusieurs personnes modifient
        le même formulaire en même temps.
      </p>
      <div class="actions">
        <button
          (click)="onOptimsticLockRefetch(message)"
          class="p-button p-button-primary"
          type="button"
          pButton
        >
          Recharger
        </button>
      </div>
    </div>
  </ng-template>
</p-toast>
