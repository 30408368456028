import {Observable} from "rxjs";

/**
 * Shell page info to display in the breadcrumb.
 *
 * They may be provided by using input parameters of the AppShellPageComponent,
 * or be specified in the route data.
 */

export interface AdminShellPageInfo {
  label: string,
  icon: string,
}

export interface LazyAdminShellPageInfo {
  lazy: true,
  label$: Observable<string>;
  icon$: Observable<string>;
}

export const ADMIN_SHELL_PAGE_INFO_ROUTE_DATA_PARAMETER = 'shellPageInfo';
