import {computed, inject, Injectable, Signal} from "@angular/core";
import {AdminFrontContextService} from "../shared/context/admin-front-context.service";
import {AdminFrontAppContext} from "../shared/context/admin-front-app-context";
import {ActivatedRoute} from "@angular/router";
import {FilterPropertiesUtils, FilterPropertyDict, PaginationUtils, TableColumn} from "@fiscalteam/ngx-nitro-services";
import {derivedAsync} from "ngxtension/derived-async";
import {map} from "rxjs";
import {ShellRoutesUtils} from "./shell-routes.utils";


@Injectable()
export class ShellListRouteHelperService {

  adminFrontContextService = inject(AdminFrontContextService);

  /**
   * Create a base search filter by patching an empty object with values from the application context.
   *
   * The resulting search filter will be patched again with values from 'filter properties', which are presented to the
   * user.
   *
   * Use this to restrict the search to resources within the current trustee context for instance:
   *
   * const searchFilter: Signal<WsSomethingSearch> = createBaseSearch({
   *   trustee: t => ({trusteeSearch: {exactTrusteeRef:  {id: t.id}}})
   * });
   *
   * where trusteeSearch.exactTrusteeRef is a property path of WsSomethingSearch.
   *
   * @param patcher An object containing the same properties as the application context,
   *                and for each of them an optional function creating a patch to apply on the search filter depending on the
   *                context value.
   */
  createBaseSearch<S extends object>(patcher: WhenValueInContextPatcher<S>): Signal<S> {
    return computed(() => {
      const context = this.adminFrontContextService.context();
      const filter = {} as S;

      for (const contextKey in patcher) {
        const typedKey = contextKey as keyof AdminFrontAppContext;
        const contextValue = context[typedKey];
        const patch = patcher[typedKey] as (value: any) => Partial<S>;
        if (patch && contextValue) {
          const constextPatch = patch(contextValue);
          Object.assign(filter, constextPatch);
        }
      }
      return filter;
    });
  }

  /**
   * Create an array of values depending on the application context.
   *
   * Use this to instantiate locked filter properties you want to present to the user.
   * For instance, when a trustee is in the context, the trustee filter property component might be displayed
   * at the top of the table to indicate the user that the filter is active.
   *
   * const baseFilterProperties = createConcatenatedList<FilterPropertyValue<MyResourceProperty, WsMyResourceSearch, any>>({
   *     financialAccountProvider: provider => [{
   *       property: MyResourceProperties.exactFinancialAccountProviderRef,
   *       filterValue: {id: provider.id},
   *       locked: true,
   *       readOnly: true,
   *     }]
   *   });
   *
   *
   * @param itemLister An object containing the same properties as the application context,
   *                    and for each of them an optional function creating a list of object depending on the context value.
   */
  createConcatenatedList<T>(itemLister: WhenValueInContextLister<T>): Signal<T[]> {
    return computed(() => {
      const context = this.adminFrontContextService.context();
      const values = [];

      for (const contextKey in itemLister) {
        const typedKey = contextKey as keyof AdminFrontAppContext;
        const contextValue = context[typedKey];
        const lister = itemLister[typedKey] as (value: any) => T[];
        if (lister && contextValue) {
          const list = lister(contextValue);
          values.push(...list);
        }
      }
      return values;
    });
  }

  /**
   * Filters a list depending on values in the context.
   *
   * For instance, when a trustee is in the context, a customer table might want to hide the "trustee" column which
   * is redundant:
   *
   *   availableColumns = createFilteredList(Object.values(MyResourceColumns), {
   *     trustee: [MyResourceColumns.trustee]
   *   });
   *
   * where MyResourceColumns.trustee is the trustee column definition.
   *
   * @param allItems Items to filter
   * @param excludedItemLister An object containing the same properties as the application context,
   *                           and for each of them a list of object to exclude from the returned list if a context
   *                           value is present.
   */
  createFilteredList<C>(allItems: C[],
                        excludedItemLister: WhenInContextLister<C>) {
    return computed(() => {
      const context = this.adminFrontContextService.context();
      let columns = [...allItems];

      for (const contextKey in excludedItemLister) {
        const typedKey = contextKey as keyof AdminFrontAppContext;
        const contextValue = context[typedKey];
        const list = excludedItemLister[typedKey];
        if (list && contextValue) {
          columns = columns.filter(c => !list.includes(c));
        }
      }
      return columns;
    });
  }

  /**
   * Obtain the columns serialized in the activated route params
   *
   * @param allColumns
   * @param activatedRoute
   */
  getRouteColumns<C extends TableColumn<any, any>>(allColumns: C[], activatedRoute: ActivatedRoute) {
    return derivedAsync(() => {
      return activatedRoute.paramMap.pipe(
        map(params => params.get(ShellRoutesUtils.ROUTE_PARAM_LIST_COLUMNS)),
        map(columns => columns ? columns.split(',') : undefined),
        map(names => names ? names.map(name => allColumns.find(c => c.name === name)).filter(c => c != null) : undefined)
      )
    });
  }

  /**
   * Obtain the filter properties serialized in the activated route params
   *
   * @param allColumns
   * @param activatedRoute
   */
  getRouteFilterProperties<C extends string, D>(allFilters: FilterPropertyDict<C, D>, activatedRoute: ActivatedRoute) {
    return derivedAsync(() => {
      return activatedRoute.paramMap.pipe(
        map(params => params.get(ShellRoutesUtils.ROUTE_PARAM_LIST_FILTER)),
        map(columns => columns ? FilterPropertiesUtils.parseFilterPropertyValues(columns, allFilters) : undefined),
      )
    });
  }

  /**
   * Obtain the pagination serialized in the activated route params
   *
   * @param allColumns
   * @param activatedRoute
   */
  getRoutePagination(activatedRoute: ActivatedRoute) {
    return derivedAsync(() => {
      return activatedRoute.paramMap.pipe(
        map(params => params.get(ShellRoutesUtils.ROUTE_PARAM_LIST_PAGINATION)),
        map(columns => columns ? PaginationUtils.parsePagination(columns) : undefined),
      )
    });
  }
}

export type WhenValueInContextPatcher<T> = { [K in keyof AdminFrontAppContext]?: WithValuePatcher<T, AdminFrontAppContext[K]> };
export type WhenValueInContextLister<T> = { [K in keyof AdminFrontAppContext]?: WithValueLister<T, AdminFrontAppContext[K]> };
export type WhenInContextLister<T> = { [K in keyof AdminFrontAppContext]?: T[] };

export type WithValuePatcher<T, V> = (value: NonNullable<V>) => Partial<T>;
export type WithValueLister<T, V> = (value: NonNullable<V>) => T[];
