import {enableProdMode, importProvidersFrom, LOCALE_ID} from '@angular/core';
import {environment} from './environments/environment';
import {NitroAuthModule, nitroAuthWithAngularOauth2Oidc, NitroConfigModule, nitroConfigWithKeyValues, NitroServiceModule, tryLoadNitroBackendUriFromJson} from "@fiscalteam/ngx-nitro-services";
import {provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {bootstrapApplication, BrowserModule} from '@angular/platform-browser';
import {AppComponent} from './app/app.component';
import {AppRoutingModule} from './app/app-routing.module';
import {provideAnimations} from '@angular/platform-browser/animations';
import {FormsModule} from '@angular/forms';
import {MessagesModule} from 'primeng/messages';
import {InputTextModule} from "primeng/inputtext";
import {MenuModule} from "primeng/menu";
import {providePrimeNG} from "primeng/config";
import localeFr from '@angular/common/locales/fr';
import Aura from '@primeng/themes/aura';
import {registerLocaleData} from "@angular/common";
import {DefaultAdminFrontConfigs} from "./app/config/admin-front-default-config";

registerLocaleData(localeFr, 'fr-FR');

if (environment.production) {
  enableProdMode();
}

tryLoadNitroBackendUriFromJson(environment.domainAppUri).then(apiUri => {
  return bootstrapApplication(AppComponent, {
    providers: [
      importProvidersFrom(BrowserModule, AppRoutingModule, NitroConfigModule.forRoot({
          domainApiUri: apiUri,
          domainApiClient: true,
          applicationName: "nitro-admin-front",
          configValues: environment.configValues,
          asyncConfigValuesUri: environment.runtimeConfigPath,
          extraFeatures: [
            nitroConfigWithKeyValues(DefaultAdminFrontConfigs, 0)
          ]
        }),
        NitroAuthModule.forRoot({
          implementation: nitroAuthWithAngularOauth2Oidc(),
          httpInterceptor: true,
        }), NitroServiceModule.forRoot(), MenuModule, MessagesModule, InputTextModule, FormsModule),
      {provide: LOCALE_ID, useValue: 'fr-FR'},
      provideAnimations(),
      provideHttpClient(withInterceptorsFromDi()),
      providePrimeNG({
        theme: {
          preset: Aura,
          options: {
            darkModeSelector: '.speed-dark',
          },
        },
      }),
    ]
  })
    .catch(err => console.error(err));
});
