import {CombinedFilterFilterProperty, ConfigValueFilterProperty, CustomerDocumentFilterProperty, CustomerTransactionFilterProperty, DocumentRuleFilterProperty, DocumentTypeCollectionFilterProperty, FilterPropertiesUtils, FilterPropertyValue, NitroRuleActionFilterProperty, NitroRuleFilterProperty, PaginationUtils, TableHelperState} from "@fiscalteam/ngx-nitro-services";
import {WsCombinedFilterSearch, WsConfigValueSearch, WsCustomerDocumentSearch, WsCustomerTransactionSearch, WsDocumentRuleSearch, WsDocumentTypeCollectionSearch, WsNitroRuleAction, WsNitroRuleActionSearch, WsNitroRuleSearch, WsRefWsCustomerDocument, WsRefWsCustomerTransaction, WsRefWsNitroRule} from "@fiscalteam/nitro-domain-client";

export class ShellRoutesUtils {

  static readonly ROUTE_PARAM_LIST_FILTER = 'filter';
  static readonly ROUTE_PARAM_LIST_PAGINATION = 'pagination';
  static readonly ROUTE_PARAM_LIST_COLUMNS = 'columns';

  static createTableStateRouteParams(s: TableHelperState<any, any, any, any>): any {
    const routeParams: any = {};
    if (s.filters && s.filters.length > 0) {
      const nonLockedFilters = s.filters.filter(f => !f.locked);
      routeParams[this.ROUTE_PARAM_LIST_FILTER] = FilterPropertiesUtils.serializeFilterPropertyValues(nonLockedFilters)
    }
    if (s.pagination) {
      routeParams[this.ROUTE_PARAM_LIST_PAGINATION] = PaginationUtils.serializePagination(s.pagination);
    }
    if (s.displayedColumns && s.displayedColumns.length > 0) {
      routeParams[this.ROUTE_PARAM_LIST_COLUMNS] = s.displayedColumns
        .map(c => c.name)
        .reduce((c, n) => c.length === 0 ? n : `${c},${n}`);
    }
    return routeParams;
  }

  static readonly customerTransactionListForFiltersFactory = (filters: FilterPropertyValue<CustomerTransactionFilterProperty, WsCustomerTransactionSearch, any>[]) => {
    const serializedFilter = FilterPropertiesUtils.serializeFilterPropertyValues(filters);
    return ['/customerTransaction/list', {filter: serializedFilter}];
  }
  static readonly customerTransactionAllForFiltersFactory = (filters: FilterPropertyValue<CustomerTransactionFilterProperty, WsCustomerTransactionSearch, any>[]) => {
    const serializedFilter = FilterPropertiesUtils.serializeFilterPropertyValues(filters);
    return ['/customerTransaction/all', {filter: serializedFilter}];
  }

  static readonly customerTransactionDetailsFactory = (transactionRef: WsRefWsCustomerTransaction) => {
    return ['/customerTransaction', transactionRef.id];
  }

  static readonly customerDocumentListForFiltersFactory = (filters: FilterPropertyValue<CustomerDocumentFilterProperty, WsCustomerDocumentSearch, any>[]) => {
    const serializedFilter = FilterPropertiesUtils.serializeFilterPropertyValues(filters);
    return ['/customerDocument/list', {filter: serializedFilter}];
  }
  static readonly customerDocumentAllForFiltersFactory = (filters: FilterPropertyValue<CustomerDocumentFilterProperty, WsCustomerDocumentSearch, any>[]) => {
    const serializedFilter = FilterPropertiesUtils.serializeFilterPropertyValues(filters);
    return ['/customerDocument/all', {filter: serializedFilter}];
  }

  static readonly customerDocumentDetailsFactory = (documentRef: WsRefWsCustomerDocument) => {
    return ['/customerDocument', documentRef.id];
  }

  static readonly combinedFilterListForFiltersFactory = (filters: FilterPropertyValue<CombinedFilterFilterProperty, WsCombinedFilterSearch, any>[]) => {
    const serializedFilter = FilterPropertiesUtils.serializeFilterPropertyValues(filters);
    return ['/configuration/filters', {filter: serializedFilter}];
  }

  static readonly nitroRuleListForFiltersFactory = (filters: FilterPropertyValue<NitroRuleFilterProperty, WsNitroRuleSearch, any>[]) => {
    const serializedFilter = FilterPropertiesUtils.serializeFilterPropertyValues(filters);
    return ['/rule/list'];
  }

  static readonly nitroRuleActionsListForFiltersFactory = (filters: FilterPropertyValue<NitroRuleActionFilterProperty, WsNitroRuleActionSearch, any>[]) => {
    const serializedFilter = FilterPropertiesUtils.serializeFilterPropertyValues(filters);
    return ['/ruleAction/list', {filter: serializedFilter}];
  }
  static readonly documentRuleForFiltersFactory = (filters: FilterPropertyValue<DocumentRuleFilterProperty, WsDocumentRuleSearch, any>[]) => {
    const serializedFilter = FilterPropertiesUtils.serializeFilterPropertyValues(filters);
    return ['/documentRule/list', {filter: serializedFilter}];
  }

  static readonly nitroRuleDetailsForRefFactory = (ref: WsRefWsNitroRule) => {
    return ['/rule', ref.id];
  }

  static readonly nitroRuleActionDetailsForRefFactory = (action: WsNitroRuleAction) => {
    return ['/ruleAction', action.id];
  }

  static readonly nitroConfigListForFiltersFactory = (filters: FilterPropertyValue<ConfigValueFilterProperty, WsConfigValueSearch, any>[]) => {
    const serializedFilter = FilterPropertiesUtils.serializeFilterPropertyValues(filters);
    return ['/configuration/configs', {filter: serializedFilter}];
  }

  static readonly nitroDocumentTypeCollectionListForFiltersFactory = (filters: FilterPropertyValue<DocumentTypeCollectionFilterProperty, WsDocumentTypeCollectionSearch, any>[]) => {
    const serializedFilter = FilterPropertiesUtils.serializeFilterPropertyValues(filters);
    return ['/document-type-collection/list', {filter: serializedFilter}];
  }
  static readonly customerdocumentsRouterLinkFactory = (customerId: bigint, filters: FilterPropertyValue<CustomerDocumentFilterProperty, WsCustomerDocumentSearch, any>[]) => {
    const serializedFilter = FilterPropertiesUtils.serializeFilterPropertyValues(filters);
    return [`/customer/${customerId}/document/list`, {filter: serializedFilter}];
  }
  static readonly customertransactionsRouterLinkFactory = (customerId: number, filters: FilterPropertyValue<CustomerTransactionFilterProperty, WsCustomerTransactionSearch, any>[]) => {
    const serializedFilter = FilterPropertiesUtils.serializeFilterPropertyValues(filters);
    return [`/customer/${customerId}/transaction/all`, {filter: serializedFilter}];
  }

}
