import {Component, OnInit} from '@angular/core';
import {Observable, of} from "rxjs";
import {AppShellRoutingService, NitroMessage, NitroMessageService} from "@fiscalteam/ngx-nitro-services";
import {ShellMenuService} from "../shell-menu.service";
import {AppShellModalHelpers} from "./app-shell-modal-helpers";
import {MessageService} from "primeng/api";
import {MessagesModule} from "primeng/messages";
import {AsyncPipe, NgForOf, NgIf} from "@angular/common";
import {ButtonDirective} from "primeng/button";
import {Toast} from "primeng/toast";
import {RouterOutlet} from "@angular/router";
import {AppShellMenuComponent} from "./app-shell-menu/app-shell-menu.component";
import {Message} from "primeng/message";
import {AdminFrontContextService} from "../../shared/context/admin-front-context.service";
import {ShellListRouteHelperService} from "../shell-list-route-helper.service";

@Component({
  selector: 'adm-app-shell',
  templateUrl: './app-shell.component.html',
  styleUrls: ['./app-shell.component.scss'],
  standalone: true,
  imports: [
    MessagesModule,
    AsyncPipe,
    ButtonDirective,
    Toast,
    RouterOutlet,
    AppShellMenuComponent,
    NgIf,
    Message,
    NgForOf
  ],
  providers: [
    ShellMenuService,
    AppShellRoutingService,
    MessageService,
    ShellListRouteHelperService,
    //
    AdminFrontContextService,
  ]
})
export class AppShellComponent implements OnInit {

  messages$: Observable<NitroMessage[]> = of([]);

  ModalKeys = AppShellModalHelpers;
  menuCollapsed: boolean = false;

  constructor(
    private messageService: NitroMessageService,
    private primengMessageService: MessageService,
  ) {
  }

  ngOnInit(): void {
    this.messages$ = this.messageService.getMessages$();
  }

  discardMessages() {
    this.messageService.discardAll();
  }

  onMessageChange(value: NitroMessage[]) {
    this.messageService.setMessages(value);
  }


  onOptimsticLockModalClose() {
    this.primengMessageService.clear(AppShellModalHelpers.OPTIMISTIC_LOCK_MODAL_KEY);
  }

  onOptimsticLockRefetch(message: any) {
    AppShellModalHelpers.callOptimisticLockReloadCallback(message);
    this.primengMessageService.clear(AppShellModalHelpers.OPTIMISTIC_LOCK_MODAL_KEY);
  }

}
