<div class="app-shell-menu flex flex-column h-screen max-w-22rem nitro-env-{{frontEnv}}">

  <div class=" header flex-0 p-4 pb-4 sticky">
    <div
      (click)="onTitleClick()"
      class="text-xl flex cursor-pointer justify-content-start"
    >
      <a class="font-bold text-primary">Speed</a>
      <a class="font-semibold text-plain">Admin</a>
    </div>

    <div *ngIf="loggedUser" class="logged-user text-md mt-2">
      <a
        [href]="keycloakUrl"
        target="_blank"
        rel="noopener noreferrer"
        class="logged-user text-md mt-2"
      >
        <span>{{ loggedUser.name }}</span>
      </a>
    </div>
    <div *ngIf="isDevelopment" class="dev-warning-message mt-2 p-2 rounded-md flex flex-column items-center">
      <div>
        <ng-container *ngFor="let i of [1,2,3,4,5]">
          <i class="pi pi-exclamation-triangle mr-1" style="font-size: 2.5rem"></i>
        </ng-container>
      </div>
      <span class="text-md font-medium">Development Environment</span>
    </div>
  </div>

  <div class="menu flex-1 mt-20  h-full overflow-y-auto overflow-x-hidden" *ngIf="menu$ | async"
       style="position: relative;">
    <div class="block main-block menu-1 w-full my-3"
         *ngFor="let mainItem of (menu$ | async)">

      <header class="main-header flex align-items-center justify-content-between py-2">
        <nit-button-link [routerLink]="mainItem.routerLink"
                         [label]="mainItem.label"
                         [icon]="mainItem.icon"
                         class="flex-1"
                         buttonStyleClass="w-full p-button-text p-button-plain justify-content-start"
        ></nit-button-link>
        <span *ngIf="mainItem.badge" class="badge flex-0">
          {{ mainItem.badge }}
        </span>
      </header>

      <div *ngFor="let mainItem of mainItem.items" class="ml-2">
        <!-- Expandable items -->
        <div *ngIf="mainItem.label === 'Transactions' || mainItem.label === 'Documents'"
             (click)="mainItem.expanded = !mainItem.expanded"
             class="main-item-toggle">
          <nit-button-link
            [routerLink]="mainItem.routerLink"
            [label]="mainItem.label"
            [icon]="'pi pi-chevron-down'"
            class="flex-1 "
            buttonStyleClass="w-full p-button-text p-button-plain text-left flex justify-content-start items-center"
          ></nit-button-link>
          <span *ngIf="mainItem.badge" class="badge flex-0">{{ mainItem.badge }}</span>
        </div>

        <!-- Non-expandable items -->
        <div *ngIf="mainItem.label !== 'Transactions' && mainItem.label !== 'Documents'"
             class="main-item">
          <nit-button-link
            [routerLink]="mainItem.routerLink"
            [label]="mainItem.label"
            [icon]="mainItem.icon"
            class="flex-1"
            buttonStyleClass="w-full p-button-text p-button-plain justify-content-start"
          ></nit-button-link>
        </div>

        <!-- Sub-items for expandable items -->
        <div *ngIf="mainItem.expanded">
          <div *ngFor="let subItem of mainItem.items">
            <nit-button-link [routerLink]="subItem.routerLink"
                             [label]="subItem.label"
                             [icon]="subItem.icon"
                             class="flex-1 ml-4"
                             buttonStyleClass="w-full p-button-text p-button-plain p-button-sm justify-content-start"
            ></nit-button-link>
          </div>
        </div>
      </div>
    </div>
  </div>

  <footer class="footer py-2">
    <div class="flex align-items-center">
      <button type="button" pButton
              class="p-button-text p-button-danger w-full justify-content-start"
              icon="pi pi-sign-out"
              (click)="onLogoutClick()"
              label="Se déconnecter"
      ></button>

      <div class="flex-none theme-switch">
        <p-button (click)="switchDarkTheme()"
                  icon="pi pi-sun"
                  title="Changer le thème"
                  [text]="true"
        ></p-button>
      </div>
    </div>

    <div class="version text-sm pl-4">
    Version {{ version }}
    </div>
  </footer>


</div>

