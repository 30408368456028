import {Component, LOCALE_ID, OnInit} from '@angular/core';
import {ErrorService, NitroEnvBannerComponent} from "@fiscalteam/ngx-nitro-services";
import {debounceTime, Observable, shareReplay} from "rxjs";
import {VERSION} from "../version";
// import { PrimeNGConfig } from 'primeng/api';  // Import PrimeNGConfig
import {RouterOutlet} from "@angular/router";
import {AsyncPipe, NgIf} from "@angular/common";

@Component({
  selector: 'adm-root',
  standalone: true,
  imports: [
    RouterOutlet,
    AsyncPipe,
    NgIf,
    NitroEnvBannerComponent
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr-FR' },
  ],
  templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit {

  backendAvailable$: Observable<boolean>;
  routeLoaded = false;
  version = VERSION;

  constructor(
    private errorService: ErrorService,
    // private primeNgConfig: PrimeNGConfig  // Inject PrimeNGConfig
  ) {
    this.backendAvailable$ = this.errorService.getBackendAvailable$().pipe(
      debounceTime(100), // Force async for change detection
      shareReplay({ refCount: true, bufferSize: 1 })
    );
  }

  async ngOnInit(): Promise<void> {
    // this.primeNgConfig.setTranslation({
    //   dateFormat: 'dd/mm/yy',
    // });
  }

  onBackendUnavailableClick() {
    if (window && window.location) {
      window.location.reload();
    }
  }
}
