import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AdminFrontConfigService} from "../config/admin-front-config.service";
import {catchError, map, Observable, of, tap} from "rxjs";
import {JobInstance} from "./batch/job-instance";
import {JobExecution} from "./batch/job-execution";
import {WsMetadataConfigKey} from "@fiscalteam/nitro-domain-client";


export type ImportJob =
  'BANK_ACCOUNT_STATEMENT'
  | 'SALES_INVOICE'
  | 'PURCHASE_INVOICE'
  | 'CREDIT_CARD_STATEMENT'
  | 'PAYROLL_STATEMENT';

@Injectable({
  providedIn: 'root'
})
export class CodaBatchService {

  customerConfigKeys?: WsMetadataConfigKey[];
  trusteeConfigKeys?: WsMetadataConfigKey[];

  constructor(
    private http: HttpClient,
    private adminFrontConfigService: AdminFrontConfigService,
  ) {
  }

  fetchLastBatchInstance$(importJob?: ImportJob): Observable<JobInstance | undefined> {
    const codaImporterApiUri = this.adminFrontConfigService.getCodaImportApiUri();
    const batchEnpoint = `${codaImporterApiUri}/batch/job/instance`;

    let params = new HttpParams()
      .append('limit', '1');
    if (importJob) {
      params = params.append('batchType', importJob);
    }


    return this.http.get<JobInstance[]>(batchEnpoint, {
      responseType: 'json',
      params: params
    }).pipe(
      map(r => r == null || r.length === 0 ? undefined : r[0]),
      catchError(_ => of(undefined)),
    );
  }

  fetchBatchExecution$(executionId: number): Observable<JobExecution | undefined> {
    const codaImporterApiUri: string = this.adminFrontConfigService.getCodaImportApiUri();
    const batchEnpoint: string = `${codaImporterApiUri}/batch/execution/${executionId}`;

    return this.http.get<JobExecution>(batchEnpoint, {
      responseType: "json"
    }).pipe(
      catchError(e => of(undefined)),
    )
  }

  triggerNewLegacyBatchInstance$(): Observable<number> {
    const codaImporterApiUri: string = this.adminFrontConfigService.getCodaImportApiUri();
    const batchEnpoint: string = `${codaImporterApiUri}/batch`;

    return this.http.post<any>(batchEnpoint, undefined, {
      responseType: "json"
    }).pipe(
      map(r => r as number)
    )
  }

  triggerNewBatchInstance$(jobName: ImportJob, params: any): Observable<number> {
    const codaImporterApiUri: string = this.adminFrontConfigService.getCodaImportApiUri();
    const batchEnpoint: string = `${codaImporterApiUri}/batch/job/importer/${jobName}`;

    return this.http.post<any>(batchEnpoint, params, {
      responseType: "json"
    }).pipe(
      map(r => r as number)
    )
  }

  listCustomerConfigKeys$(): Observable<WsMetadataConfigKey[]> {
    if (this.customerConfigKeys) {
      return of(this.customerConfigKeys);
    }

    const codaImporterApiUri: string = this.adminFrontConfigService.getCodaImportApiUri();
    const uri: string = `${codaImporterApiUri}/config/nitro/customer/keys`;

    return this.http.get(uri, {
      responseType: "json"
    }).pipe(
      map(response => response as WsMetadataConfigKey[]),
      tap({next: keys => this.customerConfigKeys = keys})
    );
  }

  listTrusteeConfigKeys$(): Observable<WsMetadataConfigKey[]> {
    const codaImporterApiUri: string = this.adminFrontConfigService.getCodaImportApiUri();
    const uri: string = `${codaImporterApiUri}/config/nitro/trustee/keys`;

    return this.http.get(uri, {
      responseType: "json"
    }).pipe(
      map(response => response as WsMetadataConfigKey[]),
      tap({next: keys => this.trusteeConfigKeys = keys})
    );
  }

}
