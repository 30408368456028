import {Directive} from '@angular/core';

@Directive({
  selector: '[admShellPageTitle]',
  standalone: true
})
export class ShellPageTitleDirective {

  constructor() {
  }

}
